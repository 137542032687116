import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")

interface DownloadLinkProps {
  title: string
  date?: string
  target?: string
  to: string
  disabled?: boolean
}

const PdfLink: React.FC<DownloadLinkProps> = props => {
  const { title, target, to, disabled, date } = props
  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div>
      <a
        className="group"
        css={[
          tw`inline-block outline-none p-2 -ml-2 hover:underline focus:underline focus:border focus:border-dotted focus:border-blue-250 focus:mt-[-1px]`,
          disabled && tw`pointer-events-none`,
        ]}
        target="__blank"
        title={title}
        href={to}
      >
        <span css={[tw`h-5 w-7 pr-2 inline-block align-middle`]}>
          {pdfIcon}
        </span>
        <span
          css={[
            tw`inline-block align-middle text-blue-250 group-hover:text-blue-300 group-active:underline focus:ml-[-1px]`,
            disabled && tw`opacity-40`,
          ]}
        >
          {text?.title}
        </span>
      </a>
      {date && (
        <span css={tw`inline-block align-middle`}>({date})</span>
      )}
    </div>
  )
}

export default PdfLink

const pdfIcon = (
  <svg
    aria-hidden
    width="100%"
    height="100%"
    viewBox="0 0 26 27"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="zq0jqv6ina" d="M0 0h20.612v26H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2.6 .5)">
        <mask id="o5zy5xd83b" fill="#fff">
          <use xlinkHref="#zq0jqv6ina" />
        </mask>
        <path
          d="M13.738 4.38h.001c0 .447-.002.892 0 1.338.004.663.466 1.15 1.127 1.159.917.012 1.835.003 2.752.003.037 0 .086.013.103-.032.018-.045-.027-.07-.053-.096-.254-.26-.51-.517-.765-.776l-3.026-3.068c-.032-.032-.062-.093-.111-.073-.05.02-.026.085-.027.13v1.416M1.662 12.968v10.145c0 .237.025.466.153.674.245.395.61.553 1.062.553h11.987c.974 0 1.948.002 2.922-.001.534-.002.912-.274 1.069-.762.058-.18.037-.37.037-.555V8.72c0-.237 0-.237-.242-.237h-3.753c-.52 0-1.018-.099-1.467-.369-.929-.558-1.4-1.386-1.406-2.47-.007-1.272-.002-2.545-.002-3.818 0-.223 0-.222-.216-.222H2.86c-.37-.001-.687.114-.933.4-.207.239-.265.523-.264.832.002 3.377.001 6.755.001 10.132m-1.66.022C.002 9.639.004 6.287 0 2.936c0-.637.131-1.23.508-1.75C1.091.38 1.891 0 2.88 0h6.441c1.208 0 2.416.002 3.623 0 .237 0 .43.073.603.245 2.27 2.259 4.544 4.514 6.82 6.767.192.191.241.424.242.674.003 1.537.002 3.074.002 4.61 0 3.603-.004 7.206.001 10.808.001.877-.295 1.616-.95 2.202-.552.493-1.212.694-1.945.694-3.84-.002-7.679 0-11.519 0-1.116 0-2.233.001-3.35-.002-.864-.002-1.595-.313-2.169-.966-.481-.549-.68-1.198-.678-1.923.005-2.611.002-5.222.002-7.833V12.99"
          fill="#385885"
          mask="url(#o5zy5xd83b)"
        />
      </g>
      <path
        d="M11.687 18.044v1.48c0 .086.005.158.12.156.341-.007.683.012 1.025-.016a1.577 1.577 0 0 0 1.442-1.352c.129-.928-.533-1.795-1.467-1.888-.311-.03-.623-.01-.935-.014-.18-.003-.184.004-.185.18v1.454m-1.111-.065v-2.09c0-.443.202-.645.641-.646.472 0 .944-.01 1.415.003 1.046.03 1.882.448 2.416 1.373.315.545.418 1.138.312 1.763-.17 1-.728 1.698-1.64 2.12-.364.167-.751.229-1.148.233-.454.005-.908.002-1.362 0-.425 0-.632-.206-.633-.628-.002-.71 0-1.419-.001-2.128M7.36 17.147c0 .212.002.424 0 .636-.002.081.03.121.113.12.186-.004.372.004.557-.01a.742.742 0 0 0 .661-.618.764.764 0 0 0-.442-.793c-.26-.11-.536-.068-.807-.068-.075 0-.082.063-.082.123v.61m-1.103.866v-2.597c0-.173 0-.173.17-.173.485 0 .97-.003 1.454 0 .844.007 1.532.498 1.818 1.296.328.916-.156 1.98-1.06 2.335-.246.097-.5.136-.762.142a6.097 6.097 0 0 1-.376 0c-.107-.004-.144.039-.143.145.004.485.002.97.001 1.455 0 .174-.001.175-.172.175-.26 0-.52-.006-.78.003-.124.004-.152-.043-.152-.158.004-.874.002-1.748.002-2.623M16.124 18.22l.001-2.39c0-.281.148-.49.388-.567a.581.581 0 0 1 .18-.02c.905 0 1.81.002 2.714-.003.126 0 .153.045.15.159a14.68 14.68 0 0 0 .001.792c.004.124-.036.164-.161.163-.64-.005-1.281-.002-1.922-.002-.251 0-.251 0-.251.244v.688c0 .182 0 .183.179.183h1.753c.181 0 .182.001.182.178 0 .251-.006.503.003.753.005.13-.035.173-.168.172-.58-.006-1.16-.003-1.74-.003-.21 0-.209 0-.209.203v1.845c0 .175 0 .176-.17.176-.255 0-.51-.007-.766.004-.136.005-.167-.045-.166-.172.005-.801.002-1.602.002-2.403"
        fill="#385885"
      />
    </g>
  </svg>
)
