import { graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import PdfSection from "../../components/DoingBusinessAtBHM/PdfSection"
import { SEO } from "../../components/SEO"
import { PdfPageTemplatePageData } from "../pdf-page-template"
import { TemplateProps } from "../templates"
import ImageHero from "../../components/Hero/ImageHero"
import tw from "twin.macro"
import { H1 } from "../../components/Typography"
import DoingBusinessTitle from "../../components/DoingBusinessAtBHM/DoingBusinessTitle"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers.js")

const PdfPageTemplate: React.FC<TemplateProps<PdfPageTemplatePageData>> = ({
  data,
  location,
}) => {
  const { title, featuredImage, content, template } = data.wpPage
  const { pdfPagePdfDownloads } = template?.pdfPage

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <>
      <SEO url={location.href} title={title} />
      <ImageHero image={featuredImage} overlay={false}>
        <div css={tw`text-center text-white container m-auto p-4 md:p-8`}>
          <H1
            css={[
              tw`text-4xl font-extrabold sm:text-5xl xl:text-6xl`,
              "text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);",
            ]}
          >
            {text?.title}
          </H1>
        </div>
      </ImageHero>
      <DoingBusinessTitle content={content} />
      {pdfPagePdfDownloads && (
        <section css={tw`px-4 sm:px-8`}>
          <PdfSection title="PDF Downloads" files={pdfPagePdfDownloads} />
        </section>
      )}
    </>
  )
}

export default PdfPageTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      template {
        ... on WpPDFPageTemplate {
          templateName
          pdfPage {
            pdfPagePdfDownloads {
              pdfPagePdfTitle
              pdfPagePdfDate
              pdfPagePdfDownload {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
