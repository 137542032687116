import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import PdfLink from "../Link/PdfLink"

interface Props {
  title: string
  files: {
    pdfPagePdfTitle: string;
    pdfPagePdfDownload: {
      localFile: {
          publicURL: string
      }
    }
    pdfPagePdfDate?: string
  }[]
}

const PdfSection: React.FC<Props> = ({files, title}) => {
  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`max-w-4xl mx-auto pt-4`]}>
      <h2 css={[tw`font-normal`]}>{text?.title}</h2>
      {files?.map((item, index) => (
        item?.pdfPagePdfDownload?.localFile?.publicURL ?
        <PdfLink key={index} title={item?.pdfPagePdfTitle} to={item?.pdfPagePdfDownload?.localFile?.publicURL} date={item?.pdfPagePdfDate} />
        :
        null
      ))}
    </article>
  )
}

export default PdfSection